// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/CustomTooltip.css */
/* src/CustomTooltip.css */
.custom-tooltip {
  background: linear-gradient(to right, #519ACE, #608AC9 );
  border-radius: 8px;
  padding: 10px;
  color: white;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.label {
  font-size: 14px;
  margin: 0;
}

.price {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.year-and-time {
  font-size: 14px;
  margin: 0;
}

/* src/BitcoinChart.css */
/* .chart-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
} */





`, "",{"version":3,"sources":["webpack://./src/styles/CustomTooltip.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B,0BAA0B;AAC1B;EACE,wDAAwD;EACxD,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,8BAA8B;EAC9B,yCAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,SAAS;AACX;AACA;EACE,eAAe;EACf,SAAS;AACX;;AAEA,yBAAyB;AACzB;;;;;GAKG","sourcesContent":["/* src/CustomTooltip.css */\n/* src/CustomTooltip.css */\n.custom-tooltip {\n  background: linear-gradient(to right, #519ACE, #608AC9 );\n  border-radius: 8px;\n  padding: 10px;\n  color: white;\n  font-family: Arial, sans-serif;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n}\n\n.label {\n  font-size: 14px;\n  margin: 0;\n}\n\n.price {\n  font-size: 16px;\n  font-weight: bold;\n  margin: 0;\n}\n.year-and-time {\n  font-size: 14px;\n  margin: 0;\n}\n\n/* src/BitcoinChart.css */\n/* .chart-container {\n  padding: 20px;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n} */\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
