// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-history-mobile{
    display: none;
}


@media screen and (max-width:1024px) {
    .transaction-history{
        display: none;
    }
    .calendar-container{
        display: none;
    }
    .filters{
        display: none;
    }
    .transaction-history-mobile{
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Activity.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;;AAGA;IACI;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;IACA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".transaction-history-mobile{\n    display: none;\n}\n\n\n@media screen and (max-width:1024px) {\n    .transaction-history{\n        display: none;\n    }\n    .calendar-container{\n        display: none;\n    }\n    .filters{\n        display: none;\n    }\n    .transaction-history-mobile{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
