import './App.css';
import CreateAccount from '../src/pages/CreateAccount'
import '../src/styles/CreateAccount.css'
import '../src/styles/HomePage.css'



function App() {
  return (
    <div className="App">
      <CreateAccount />
      
    </div>
  );
}

export default App;
